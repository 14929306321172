import './App.css';

function App() {
  return (
    <>
      <div id="container">
        <div id="content">
          <h1>Skwzbxx</h1>
          <p>
            Skwzbxx (pronounced squeeze&middot;box) was a pop/ska/rock/funk influenced band from Charleston, South
            Carolina known for their wide range of amazing music and high-energy stage show.
          </p>

          <div class="splitcontentleft">
            <div class="box small">
              <h3>Skronk</h3>
              n. Slang., Popular music originating in Charleston, South Carolina, USA in the late 1990s having elements
              of ska, rock, and funk. Coined by Charlton Singleton, cornet player of the band Skwzbxx.
              <br />
              <br />
              Blend: <em>ska, rock, funk.</em>
            </div>
            <div class="box small">
              If you have any Skwzbxx memorabilia, including photos, video, newspaper clippings, set lists, bootleg
              tapes, or whatever, please contact <a href="mailto:david.cole@digitalcharleston.com">David Cole</a>.
            </div>
          </div>
          <div class="splitcontentright">
            <img src="jon.jpg" width="250" height="413" alt="jon holt" align="right" />
            <h2>The Band</h2>
            <ul>
              <li>Rik Crib: lead vocals, guitar</li>
              <li>David Cole: tenor saxophone</li>
              <li>
                <a href="http://www.charltonsingleton.com/" style={{ fontWeight: 'normal', color: '#cccccc' }}>
                  Charlton Singleton
                </a>
                : cornet, backing vocals
              </li>
              <li>Steve Spaulding: trombone, backing vocals</li>
              <li>Brad MacLean: guitar</li>
              <li>Jon Holt: bass guitar</li>
              <li>Jack Burg: drums, backing vocals</li>
            </ul>
            <h2>The Music</h2>
            <ul style={{ marginTop: 0 }}>
              <li>
                <a href="/music/charleston_local_rock_showcase.1996/07%20War%20on%20Peace.mp3">War on Peace</a>, 1996
              </li>
              <li>
                <a href="/music/charleston_local_rock_showcase.1997/08%20Skip-Bo.mp3">Skip-Bo</a>, 1997
              </li>
              <li>
                <a href="/music/ghost_demo/01%20Thursday.mp3">Thursday</a>, 1998
              </li>
              <li>
                <a href="/music/ghost_demo/02%20Turn%20the%20Lights%20Down.mp3">Turn the Lights Down</a>, 1998
              </li>
              <li>
                <a href="/music/ghost_demo/03%20Maybe.mp3">Maybe</a>, 1998
              </li>
              <li>
                <a href="/music/ghost_demo/04%20Skip-Bo%203.mp3">Skip-Bo 3</a>, 1998
              </li>
              <li>
                <a href="/music/freak_in_my_candy/01%20Nothing%20to%20Say.mp3">Nothing To Say</a>, 1999
              </li>
              <li>
                <a href="/music/freak_in_my_candy/02%20Codeine.mp3">Codeine</a>, 1999
              </li>
              <li>
                <a href="/music/freak_in_my_candy/03%20Stay%20Awake.mp3">Stay Awake</a>, 1999
              </li>
              <li>
                <a href="/music/freak_in_my_candy/05%20Shine.mp3">Shine</a>, 1999
              </li>
              <li>
                <a href="/music/freak_in_my_candy/06%20Love%20Leave%20Me%20on%20the%20Ground.mp3">
                  Love Leave Me on the Ground
                </a>
                , 1999
              </li>
              <li>
                <a href="/music/freak_in_my_candy/07%20One%20of%20These%20Days.mp3">One of These Days</a>, 1999
              </li>
            </ul>
            {/* <a href="/music.html">More Skwzbxx music</a>. */}
          </div>
          <br clear="all" />
          <br />
        </div>
        <div id="footer">
          <p>&copy; 1996 - {new Date().getFullYear()} Skwzbxx LLC</p>
        </div>
      </div>
      <script src="http://www.google-analytics.com/urchin.js" type="text/javascript"></script>
      <script type="text/javascript">_uacct = 'UA-250341-25'; urchinTracker();</script>
    </>
  );
}

export default App;
